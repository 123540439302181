var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "mega-modal",
    {
      ref: "modal-2",
      staticClass: "modal-sm",
      attrs: { active: _vm.modal, center: true },
      on: {
        onClose: function($event) {
          _vm.modal = false
        }
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "card-body p-4",
          on: {
            keypress: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              return _vm.submit.apply(null, arguments)
            }
          }
        },
        [
          _c("date-picker", {
            staticClass: "w-100",
            staticStyle: { width: "100%" },
            attrs: {
              type: "datetimerange",
              "range-separator": "—",
              "start-placeholder": _vm.$t("graph_start_date"),
              "end-placeholder": _vm.$t("graph_end_date")
            },
            model: {
              value: _vm.form.timespan,
              callback: function($$v) {
                _vm.$set(_vm.form, "timespan", $$v)
              },
              expression: "form.timespan"
            }
          }),
          _vm._v(" "),
          _c("mega-input", {
            staticClass: "mb-0 mt-3",
            attrs: {
              type: "text",
              label: _vm.$t("advert_result_link"),
              placeholder: _vm.$t("advert_result_link")
            },
            model: {
              value: _vm.form.link,
              callback: function($$v) {
                _vm.$set(_vm.form, "link", $$v)
              },
              expression: "form.link"
            }
          }),
          _vm._v(" "),
          _c("mega-select", {
            staticClass: "mb-0 mt-3",
            attrs: {
              label: _vm.$t("advert_place"),
              placeholder: _vm.$t("round_top_not_selected"),
              options: [
                { value: 1, name: _vm.$t("advert_on_app_enter") },
                { value: 2, name: _vm.$t("advert_on_feed") }
              ]
            },
            model: {
              value: _vm.form.where_to_show,
              callback: function($$v) {
                _vm.$set(_vm.form, "where_to_show", $$v)
              },
              expression: "form.where_to_show"
            }
          }),
          _vm._v(" "),
          _c("mega-range", {
            staticClass: "bg-primary mt-3",
            attrs: {
              label: _vm.$t("advert_frequency"),
              min: 1,
              max: 100,
              step: 1
            },
            model: {
              value: _vm.form.show_count,
              callback: function($$v) {
                _vm.$set(_vm.form, "show_count", $$v)
              },
              expression: "form.show_count"
            }
          }),
          _vm._v(" "),
          _c("mega-number", {
            attrs: {
              min: 1,
              max: 100,
              step: 1,
              help: _vm.form.show_count + "%"
            },
            model: {
              value: _vm.form.show_count,
              callback: function($$v) {
                _vm.$set(_vm.form, "show_count", $$v)
              },
              expression: "form.show_count"
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "navbar sm-down:flex-column-reverse md-up:justify-content-end"
            },
            [
              _c(
                "mega-button",
                {
                  staticClass: "bg-white sm-down:w-100 md-up:mr-2",
                  on: {
                    click: function($event) {
                      _vm.modal = false
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("close")))]
              ),
              _vm._v(" "),
              _c(
                "mega-button",
                {
                  staticClass: "btn-primary sm-down:w-100 sm-down:mb-3",
                  attrs: { disabled: !_vm.valid },
                  on: { click: _vm.submit }
                },
                [_vm._v(_vm._s(_vm.$t("advert_round")))]
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }