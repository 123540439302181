<template>
  <mega-modal
    ref="modal-2"
    class="modal-sm"
    :active="modal"
    :center="true"
    @onClose="modal = false"
  >
    <div class="card-body p-4" @keypress.enter="submit">
      <date-picker
        class="w-100"
        style="width: 100%"
        v-model="form.timespan"
        type="datetimerange"
        range-separator="—"
        :start-placeholder="$t('graph_start_date')"
        :end-placeholder="$t('graph_end_date')"
      >
      </date-picker>
      <mega-input
        class="mb-0 mt-3"
        type="text"
        :label="$t('advert_result_link')"
        :placeholder="$t('advert_result_link')"
        v-model="form.link"
      />
      <mega-select
        class="mb-0 mt-3"
        :label="$t('advert_place')"
        :placeholder="$t('round_top_not_selected')"
        :options="[
          { value: 1, name: $t('advert_on_app_enter') },
          { value: 2, name: $t('advert_on_feed') }
        ]"
        v-model="form.where_to_show"
      />
      <mega-range
        class="bg-primary mt-3"
        :label="$t('advert_frequency')"
        v-model="form.show_count"
        :min="1"
        :max="100"
        :step="1"
      />
      <mega-number
        :min="1"
        :max="100"
        :step="1"
        v-model="form.show_count"
        :help="form.show_count + '%'"
      />
      <div class="navbar sm-down:flex-column-reverse md-up:justify-content-end">
        <mega-button
          class="bg-white sm-down:w-100 md-up:mr-2"
          @click="modal = false"
          >{{ $t("close") }}</mega-button
        >
        <mega-button
          class="btn-primary sm-down:w-100 sm-down:mb-3"
          @click="submit"
          :disabled="!valid"
          >{{ $t("advert_round") }}</mega-button
        >
      </div>
    </div>
  </mega-modal>
</template>

<script>
import { DatePicker } from "element-ui";

export default {
  props: ["round", "enabled", "likes"],
  components: {
    DatePicker
  },
  data() {
    return {
      modal: false,
      form: {
        link: "",
        show_count: 1,
        where_to_show: 1,
        timespan: ""
      }
    };
  },
  computed: {
    valid() {
      return !!this.form.link;
    }
  },
  methods: {
    open() {
      this.modal = true;
    },

    close() {
      this.modal = false;
    },

    submit() {
      function timestamp(string) {
        return Math.floor(new Date(string).getTime() / 1000);
      }

      const data = {
        round_id: Number(this.round),
        link: this.form.link,
        show_count: this.form.show_count,
        show_on_startup: this.form.where_to_show === 1,
        show_in_feed: this.form.where_to_show === 2,
        starts_at: timestamp(this.form.timespan[0]),
        ends_at: timestamp(this.form.timespan[1])
      };

      this.$api.v2base
        .post("/round/adcomp", data)
        .then(() => {
          this.close();
          this.$alert.success(this.$t("advert_success"));
        })
        .catch(() => {
          this.$alert.danger(this.$t("err_server"));
        });
    }
  }
};
</script>
