<template>
  <section class="bg-white">
    <div class="container xs:d-none">
      <div
        class="row"
        style="
          justify-content: space-around;
        "
      >
        <div v-if="!longVideo" class="p-4" style="line-height: 1">
          <h4 class="mb-0 bold xs:d-inline">{{ $t("comments") }}</h4>
          <span
            class="h3 font-light text-primary"
            :class="{ 'text-hide': comments === undefined }"
            style="transition: .3s"
            >{{ comments }}</span
          >
        </div>

        <div class="p-4" style="line-height: 1">
          <h4 class="mb-0 bold xs:d-inline">{{ $t("views") }}</h4>
          <span
            class="h3 font-light text-primary"
            :class="{ 'text-hide': views === undefined }"
            style="transition: .3s"
            >{{ views }}</span
          >
          <input
            class="views-input align-self-center"
            style="
              width: 10ch; 
              height: 3ch;
              vertical-align: text-bottom;
            "
            type="number"
            @click.stop
            @keydown.enter="changeViews"
          />
        </div>

        <div class="p-4" style="line-height: 1">
          <h4 class="mb-0 bold xs:d-inline">{{ $t("likes") }}</h4>
          <span
            class="h3 font-light text-primary"
            :class="{ 'text-hide': likes === undefined }"
            style="transition: .3s"
            >{{ likes }}</span
          >
          <input
            class="likes-input align-self-center"
            style="
              width: 10ch; 
              height: 3ch;
              vertical-align: text-bottom;
            "
            type="number"
            @click.stop
            @keydown.enter="changeLikes"
          />
        </div>

        <div v-if="!longVideo" class="p-4" style="line-height: 1">
          <h4 class="mb-0 bold xs:d-inline">{{ $t("dislikes") }}</h4>
          <span
            class="h3 font-light text-primary"
            :class="{ 'text-hide': dislikes === undefined }"
            style="transition: .3s"
            >{{ dislikes }}</span
          >
        </div>

        <!-- <div v-if="longVideo" class="p-4" style="line-height: 1">
          <h4 class="mb-0 bold xs:d-inline">{{ $t("reposts") }}</h4>
          <span
            class="h3 font-light text-primary"
            :class="{ 'text-hide': reposts === undefined }"
            style="transition: .3s"
            >{{ reposts }}</span
          >
        </div> -->
      </div>
    </div>

    <div class="container py-3 sm-up:d-none">
      <nav class="nav flex-column">
        <span v-if="!longVideo" class="btn bg-white text-left w-100">
          <i class="i-message-square float-left"></i>
          <span>{{ $t("comments") }} - {{ comments }}</span>
        </span>

        <span class="btn bg-white text-left w-100">
          <i class="i-eye float-left"></i>
          <span>{{ $t("views") }} - {{ views }}</span>
        </span>

        <span class="btn bg-white text-left w-100">
          <i class="i-thumbs-up float-left"></i>
          <span>{{ $t("likes") }} - {{ likes }}</span>
        </span>

        <span v-if="!longVideo" class="btn bg-white text-left w-100">
          <i class="i-thumbs-down float-left"></i>
          <span>{{ $t("dislikes") }} - {{ dislikes }}</span>
        </span>

        <span v-if="longVideo" class="btn bg-white text-left w-100">
          <i class="i-share float-left"></i>
          <span>{{ $t("reposts") }} - {{ reposts }}</span>
        </span>
      </nav>
    </div>
  </section>
</template>

<script>
export default {
  name: "ModelNewPage",
  props: {
    longVideo: {
      type: Boolean
    },
    dislikes: {
      type: Number
    },
    likes: {
      type: Number
    },
    views: {
      type: Number
    },
    comments: {
      type: Number
    },
    reposts: {
      type: Number
    }
  },
  methods: {
    changeViews(e) {
      let params = {
        idt_round: Number(this.$route.params.roundId),
        view_count: Number(e.target.value)
      };

      if (params.view_count < 0 || !e.target.value) {
        this.$alert.danger(this.$t("round_views_greater"));
        return;
      }

      this.$api.v2base
        .put("/round/view_count", params)
        .then(() => {
          this.$alert.success(this.$t("round_views_success"));
        })
        .catch(() => {
          this.$alert.danger("Server error");
        });

      this.views = Number(e.target.value);
      setTimeout(() => {
        e.target.value = "";
      }, 0);
    },

    changeLikes(e) {
      let params = {
        idt_round: Number(this.$route.params.roundId),
        likes_count: Number(e.target.value)
      };

      if (params.likes_count < 0 || !e.target.value) {
        this.$alert.danger(this.$t("round_likes_greater"));
        return;
      }

      this.$api.v2base
        .put("/round/likesCount", params)
        .then(() => {
          this.$alert.success(this.$t("round_likes_success"));
        })
        .catch(() => {
          this.$alert.danger("Server error");
        });

      this.likes = Number(e.target.value);
      setTimeout(() => {
        e.target.value = "";
      }, 0);
    }
  }
};
</script>
