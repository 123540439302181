var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("mega-card", { attrs: { title: _vm.$t("round_pos") } }, [
    _c(
      "div",
      {
        staticClass: "card-media ratio",
        style: { paddingBottom: _vm.padding + "%" }
      },
      [
        _c(
          "div",
          { staticClass: "position-control", on: { click: _vm.panToPosition } },
          [
            _c("img", {
              staticStyle: { width: "30px" },
              attrs: { src: require("../../../../assets/cur_position.png") }
            })
          ]
        ),
        _vm._v(" "),
        _c(
          "gmap-map",
          {
            ref: "map",
            staticClass: "fill-parent",
            staticStyle: { position: "absolute" },
            attrs: {
              center: { lat: _vm.lat, lng: _vm.lng },
              zoom: 17,
              options: {
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false
              },
              "map-type-id": "terrain"
            }
          },
          [
            _c("gmap-marker", {
              attrs: { position: { lat: _vm.lat, lng: _vm.lng } }
            })
          ],
          1
        )
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "card-body" }, [
      _c("p", { staticClass: "mb-0 d-inline mr-2" }, [
        _vm._v(_vm._s(_vm.place))
      ]),
      _vm._v(" "),
      _c("small", { staticClass: "text-muted" }, [_vm._v(_vm._s(_vm.address))])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }