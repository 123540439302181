var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "models-page" },
    [
      _vm.round["likes"]
        ? _c("section-stats", {
            attrs: {
              longVideo: _vm.round.long_video,
              dislikes: _vm.round["likes"]["dislikes_count"],
              likes: _vm.round["likes"]["likes_count"],
              views: _vm.round["view_count"],
              comments: _vm.round["comments"]["count"]
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("section", [
        _c("div", { staticClass: "container py-5" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "md-down:col-12 lg:col-5 xl:col-3" },
              [
                _c(
                  "mega-card",
                  { staticClass: "card-border card-round" },
                  [
                    !_vm.round.long_video
                      ? _c(
                          "div",
                          {
                            staticClass: "p-absolute z-9",
                            style: { top: "8px", right: "8px" },
                            on: {
                              click: function($event) {
                                return _vm.toggleFavorite(
                                  _vm.round["in_favourite"]
                                )
                              }
                            }
                          },
                          [
                            _c("i", {
                              class: `${
                                _vm.round["in_favourite"]
                                  ? "i-star-solid"
                                  : "i-star"
                              }`,
                              style: {
                                fontSize: "32px",
                                color: `${
                                  _vm.round["in_favourite"]
                                    ? "rgb(255, 205, 54)"
                                    : "#fff"
                                }`
                              }
                            })
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.video_link
                      ? _c(
                          "div",
                          {
                            ref: "media",
                            staticClass: "card-media cursor-pointer card-video",
                            on: { click: _vm.video_toggle }
                          },
                          [
                            !_vm.round["url_preview"]
                              ? _c("mega-image", {
                                  attrs: {
                                    ratio: "9x16",
                                    src: _vm.round["url_preview"]
                                  }
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.round["url_preview"]
                              ? _c("img", {
                                  staticClass: "w-100",
                                  attrs: {
                                    src: _vm.round["url_preview"],
                                    alt: ""
                                  }
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "media-overlay overlay-90 overlay-dark media-video active z-100"
                              },
                              [
                                _c("video", {
                                  ref: "video",
                                  staticClass: "video-player fill-parent",
                                  attrs: { src: _vm.video_link, loop: "" }
                                })
                              ]
                            ),
                            _vm._v(" "),
                            !_vm.playVideo
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "media-overlay overlay-50 overlay-dark z-200"
                                  },
                                  [
                                    _c("div", { staticClass: "text-center" }, [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "sticker sticker-lg bg-white"
                                        },
                                        [_c("i", { staticClass: "i-play" })]
                                      )
                                    ])
                                  ]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      : _vm.round.youtube
                      ? _c(
                          "div",
                          { staticClass: "card-media" },
                          [
                            _c("mega-youtube", {
                              attrs: {
                                ratio: "16x9",
                                src: _vm.round.youtube.id
                              }
                            })
                          ],
                          1
                        )
                      : _c(
                          "div",
                          { staticClass: "card-media" },
                          [
                            !_vm.round["url_preview"]
                              ? _c("mega-image", {
                                  attrs: {
                                    ratio: "9x16",
                                    src: _vm.round["url_preview"]
                                  }
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.round["url_preview"]
                              ? _c("img", {
                                  staticClass: "w-100",
                                  attrs: {
                                    src: _vm.round["url_preview"],
                                    alt: ""
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        ),
                    _vm._v(" "),
                    _c(
                      "router-link",
                      {
                        staticClass: "navbar py-3 text-dark",
                        attrs: {
                          to: {
                            name: _vm.profileType,
                            params: { id: _vm.author.id }
                          }
                        }
                      },
                      [
                        _c("div", { staticClass: "container-fluid" }, [
                          _c(
                            "div",
                            { staticClass: "navbar-label" },
                            [
                              _c("mega-image", {
                                staticClass: "rounded-full navbar-avatar mr-3",
                                staticStyle: { overflow: "hidden" },
                                attrs: {
                                  ratio: "1x1",
                                  small: "",
                                  src: _vm.author.avatar
                                }
                              }),
                              _vm._v(" "),
                              _c("div", { staticClass: "content" }, [
                                _c("small", { staticClass: "strong" }, [
                                  _vm.profileType === "Business"
                                    ? _c("i", {
                                        staticClass:
                                          "i-globe text-muted text-arround align-middle mr-1"
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    [
                                      _vm._v(
                                        _vm._s(_vm.author.name) +
                                          "\n                        "
                                      ),
                                      _vm.author.verified
                                        ? _c(
                                            "IconBase",
                                            {
                                              attrs: {
                                                viewBox: "0 0 312 311",
                                                iconColor: "#45C1C9"
                                              }
                                            },
                                            [_c("UserVerified")],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]),
                                _vm._v(" "),
                                _c("small", { staticClass: "text-muted" }, [
                                  _vm._v("@" + _vm._s(_vm.author.username))
                                ])
                              ])
                            ],
                            1
                          )
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "nav",
                      { staticClass: "nav flex-column" },
                      [
                        _vm.profileType === "Business"
                          ? _c(
                              "router-link",
                              {
                                staticClass: "nav-link",
                                attrs: {
                                  to: {
                                    name: "User",
                                    params: {
                                      id: _vm.round["profile"]["idt_user"]
                                    }
                                  }
                                }
                              },
                              [
                                _c("i", { staticClass: "i-user" }),
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(_vm._s(_vm.round["profile"].name))
                                ])
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticClass: "nav-text text-muted" }, [
                          _c("i", { staticClass: "i-calendar" }),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm._f("u2d")(
                                  _vm.round["a_time"],
                                  "MMMM DD YYYY - LT"
                                )
                              )
                            )
                          ])
                        ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.modification.idt_model
                  ? _c(
                      "mega-card",
                      { attrs: { title: _vm.$t("round_model") } },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "navbar py-3 text-dark",
                            attrs: {
                              to: {
                                name: _vm.type,
                                params: {
                                  model_id: _vm.modification.idt_model,
                                  modification_id:
                                    _vm.modification.idt_model_modif
                                }
                              }
                            }
                          },
                          [
                            _c("div", { staticClass: "container-fluid" }, [
                              _c(
                                "div",
                                { staticClass: "navbar-label" },
                                [
                                  _c("mega-image", {
                                    staticClass:
                                      "rounded navbar-avatar mr-3 overflow-hidden",
                                    attrs: {
                                      small: "",
                                      saveAsImg: "",
                                      src: _vm.modification["url_icon"]
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "content" }, [
                                    _c("small", { staticClass: "strong" }, [
                                      _vm._v(_vm._s(_vm.modification.name))
                                    ]),
                                    _vm._v(" "),
                                    _c("small", { staticClass: "text-muted" }, [
                                      _vm._v(
                                        "#" + _vm._s(_vm.modification.idt_model)
                                      )
                                    ])
                                  ])
                                ],
                                1
                              )
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.round.is_location_hidden
                  ? _c("qr-code", { attrs: { uri: _vm.qrParams } })
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "mega-card",
                  [
                    _c(
                      "a",
                      {
                        staticClass: "btn text-primary rounded-0 d-block",
                        attrs: { target: "_blank", href: _vm.web_link }
                      },
                      [_vm._v(_vm._s(_vm.$t("open_web")))]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "p-relative mt-1" },
                      [
                        !_vm.readyForPush
                          ? _c(
                              "span",
                              {
                                staticClass: "p-absolute z-3 text-white",
                                style: {
                                  left: "50%",
                                  top: "50%",
                                  transform: "translate(-50%,-50%)",
                                  width: "100%",
                                  textAlign: "center",
                                  fontSize: "16px"
                                }
                              },
                              [_vm._v(_vm._s(_vm.timeUntilPushReady))]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.readyForPush
                          ? _c("div", {
                              staticClass: "p-absolute z-2",
                              style: {
                                left: 0,
                                top: 0,
                                height: "40px",
                                width: "100%",
                                background: "rgba(0,0,0,0.75)"
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "mega-button",
                          {
                            staticClass: "btn-primary rounded-0 w-100",
                            attrs: { disabled: !_vm.readyForPush },
                            on: { click: _vm.round_to_top }
                          },
                          [_vm._v(_vm._s(_vm.$t("round_to_top")))]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    !_vm.round.long_video
                      ? _c(
                          "mega-button",
                          {
                            staticClass: "btn-primary rounded-0 w-100 mt-1",
                            on: { click: _vm.round_advertise }
                          },
                          [_vm._v(_vm._s(_vm.$t("advert_round")))]
                        )
                      : _vm._e()
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "mega-card",
                  { staticClass: "mt-4" },
                  [
                    _c(
                      "mega-button",
                      {
                        staticClass:
                          "btn btn-default text-danger rounded-0 w-100 text-uppercase",
                        on: { click: _vm.delete_round }
                      },
                      [_vm._v(_vm._s(_vm.$t("del")))]
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col" },
              [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "sm-up:col-12" },
                    [
                      _vm.round.idt_round
                        ? _c("chart", {
                            attrs: {
                              endpoint:
                                _vm.author.type === "arr"
                                  ? "/stat/arp/roundviews"
                                  : "/stat/user/roundviews",
                              chartName: "views",
                              doubleGraph: false,
                              idt_round: _vm.round.idt_round
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                !_vm.round.long_video
                  ? _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "sm-down:col-12 md:col-6 lg:col-12 xl:col-6"
                        },
                        [
                          _vm.round.idt_round
                            ? _c("chart", {
                                attrs: {
                                  endpoint:
                                    _vm.author.type === "arr"
                                      ? "/stat/arp/roundlikes"
                                      : "/stat/user/roundlikes",
                                  chartName: "likes",
                                  doubleGraph: false,
                                  idt_round: _vm.round.idt_round
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "sm-down:col-12 md:col-6 lg:col-12 xl:col-6"
                        },
                        [
                          _vm.round.idt_round
                            ? _c("chart", {
                                attrs: {
                                  endpoint: "/stat/share/round",
                                  chartName: "shares",
                                  doubleGraph: false,
                                  idt_round: _vm.round.idt_round
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.round.long_video
                  ? _c(
                      "div",
                      { staticClass: "sm-up:col-12 px-1" },
                      [
                        _c(
                          "mega-card",
                          {
                            staticClass: "d-flex flex-column",
                            attrs: { title: _vm.$t("title") }
                          },
                          [
                            _c("mega-input", {
                              staticClass: "px-3",
                              attrs: { max: 30 },
                              model: {
                                value: _vm.round.title,
                                callback: function($$v) {
                                  _vm.$set(_vm.round, "title", $$v)
                                },
                                expression: "round.title"
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "btn btn-primary mr-3 mb-2 font-small align-self-end",
                                on: { click: _vm.changeRoundTitle }
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.$t("change")) +
                                    "\n              "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "sm-up:col-12 px-1" },
                  [
                    _c(
                      "mega-card",
                      {
                        staticClass: "d-flex flex-column",
                        attrs: { title: _vm.$t("desc") }
                      },
                      [
                        _c("mega-textarea", {
                          staticClass: "px-3",
                          attrs: { max: 300 },
                          model: {
                            value: _vm.round.message,
                            callback: function($$v) {
                              _vm.$set(_vm.round, "message", $$v)
                            },
                            expression: "round.message"
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass:
                              "btn btn-primary mr-3 mb-2 font-small align-self-end",
                            on: { click: _vm.changeRoundDescription }
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.$t("change")) +
                                "\n              "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "sm-up:col-12 px-1" },
                  [
                    _c(
                      "mega-card",
                      {
                        staticClass: "d-flex flex-column",
                        attrs: { title: _vm.$t("category") }
                      },
                      [
                        _vm.round.long_video
                          ? _c("mega-select", {
                              staticClass: "px-3",
                              attrs: { options: _vm.categories }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "no-ssr",
                  [
                    !_vm.round.is_location_hidden && !_vm.round.long_video
                      ? _c("map-card", {
                          attrs: {
                            ratio: "16x9",
                            place: _vm.round["place_address"],
                            address: _vm.round["place_name"],
                            lat: _vm.round.lat || 0,
                            lng: _vm.round.lng || 0
                          }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.round.is_location_hidden
                  ? _c("qr-code", { attrs: { uri: _vm.qrParams } })
                  : _vm._e(),
                _vm._v(" "),
                _c("History", {
                  attrs: { modif_id: _vm.modification["idt_model_modif"] },
                  on: { onGetLastPush: _vm.validatePushToTop }
                })
              ],
              1
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("delete-confirm", {
        ref: "delete-confirm",
        attrs: {
          title: `${_vm.$t("del_round")} #${_vm.roundId}`,
          text: _vm.$t("del_confirm"),
          round: _vm.roundId
        },
        on: { confirm: _vm.deleteRound }
      }),
      _vm._v(" "),
      _c("to-top", {
        ref: "to-top",
        attrs: {
          round: _vm.roundId,
          enabled: _vm.readyForPush,
          likes: Number(_vm.likes.count)
        }
      }),
      _vm._v(" "),
      !_vm.round.long_video
        ? _c("advertise", { ref: "advertise", attrs: { round: _vm.roundId } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }