function formatStartDate(start_date) {
  start_date =
    ("00" + start_date.getDate()).slice(-2) +
    "-" +
    ("00" + (start_date.getMonth() + 1)).slice(-2) +
    "-" +
    start_date.getFullYear() +
    " " +
    ("00" + start_date.getHours()).slice(-2) +
    ":" +
    ("00" + start_date.getMinutes()).slice(-2) +
    ":" +
    ("00" + start_date.getSeconds()).slice(-2);

  return start_date;
}

function formatEndDate(end_date) {
  end_date =
    ("00" + end_date.getDate()).slice(-2) +
    "-" +
    ("00" + (end_date.getMonth() + 1)).slice(-2) +
    "-" +
    end_date.getFullYear() +
    " " +
    ("00" + end_date.getHours()).slice(-2) +
    ":" +
    ("00" + end_date.getMinutes()).slice(-2) +
    ":" +
    ("00" + end_date.getSeconds()).slice(-2);

  return end_date;
}

function dataMapper(
  data,
  interval,
  start_date,
  end_date,
  graph,
  subGraph = undefined
) {
  switch (interval) {
    case "year": {
      let arr = [];
      data.map(item => {
        arr.push({
          year: item.year,
          value: item.count
        });
      });
      subGraph
        ? arr.map(item => {
            this.dataset[graph][subGraph][item.year] = item.value;
          })
        : arr.map(item => {
            this.dataset[graph][item.year] = item.value;
          });
      break;
    }

    case "quarter": {
      let arr = [];
      data.map(item => {
        arr.push({
          date: item.year + "-" + item.quarter,
          value: item.count
        });
      });
      subGraph
        ? arr.map(item => {
            this.dataset[graph][subGraph][item.date] = item.value;
          })
        : arr.map(item => {
            this.dataset[graph][item.date] = item.value;
          });
      break;
    }

    case "month": {
      let months = [
        this.$t("date.months.short.jan"),
        this.$t("date.months.short.feb"),
        this.$t("date.months.short.mar"),
        this.$t("date.months.short.apr"),
        this.$t("date.months.short.may"),
        this.$t("date.months.short.jun"),
        this.$t("date.months.short.jul"),
        this.$t("date.months.short.aug"),
        this.$t("date.months.short.sep"),
        this.$t("date.months.short.oct"),
        this.$t("date.months.short.nov"),
        this.$t("date.months.short.dec")
      ];

      let arr = [];
      data.map(item => {
        arr.push({
          date: item.month - 1 + "." + item.year,
          value: item.count
        });
      });

      subGraph
        ? arr.map(item => {
            this.dataset[graph][subGraph][
              months[item.date.split(".")[0]] + " " + item.date.split(".")[1]
            ] = item.value;
          })
        : arr.map(item => {
            this.dataset[graph][
              months[item.date.split(".")[0]] + " " + item.date.split(".")[1]
            ] = item.value;
          });

      break;
    }

    case "week": {
      let arr = [];

      data.map(item => {
        arr.push({
          year: item.year,
          date: item.week,
          value: item.count
        });
      });

      arr.push("");

      let new_arr = [];

      arr.reduce((acc, curVal) => {
        if (curVal.date === acc.date) {
          acc.value += curVal.value;
          return acc;
        } else {
          new_arr.push(acc);
          return curVal;
        }
      });

      subGraph
        ? new_arr.map(item => {
            let week = new Date(item.year, 0, item.date * 7 - 3);
            let last_week = new Date(item.year, 0, item.date * 7 - 9);

            this.dataset[graph][subGraph][
              last_week.getDate() +
                "." +
                (last_week.getMonth() + 1) +
                " - " +
                week.getDate() +
                "." +
                (week.getMonth() + 1) +
                "." +
                week.getFullYear()
            ] = item.value;
          })
        : new_arr.map(item => {
            let week = new Date(item.year, 0, item.date * 7 - 3);
            let last_week = new Date(item.year, 0, item.date * 7 - 9);

            this.dataset[graph][
              last_week.getDate() +
                "." +
                (last_week.getMonth() + 1) +
                " - " +
                week.getDate() +
                "." +
                (week.getMonth() + 1) +
                "." +
                week.getFullYear()
            ] = item.value;
          });
      break;
    }

    case "day": {
      let arr = [];
      data.map(item => {
        arr.push({
          date:
            item.day +
            "." +
            (item.month.toString().length < 2 ? "0" + item.month : item.month) +
            "." +
            item.year,
          value: item.count
        });
      });

      subGraph
        ? arr.map(item => {
            this.dataset[graph][subGraph][
              item.date.split(".")[0] +
                "." +
                item.date.split(".")[1] +
                "." +
                item.date.split(".")[2]
            ] = item.value;
          })
        : arr.map(item => {
            this.dataset[graph][
              item.date.split(".")[0] +
                "." +
                item.date.split(".")[1] +
                "." +
                item.date.split(".")[2]
            ] = item.value;
          });
      break;
    }

    case "hour": {
      let arr = [];
      data.map(item => {
        arr.push({
          date:
            item.day +
            "." +
            (item.month.toString().length < 2 ? "0" + item.month : item.month) +
            " " +
            (item.hour
              ? item.hour.toString().length < 2
                ? "0" + item.hour
                : item.hour
              : "00") +
            ":00",
          value: item.count
        });
      });
      console.log(arr);

      subGraph
        ? arr.map(item => {
            this.dataset[graph][subGraph][
              item.date.split(".")[0] + "." + item.date.split(".")[1]
            ] = item.value;
          })
        : arr.map(item => {
            this.dataset[graph][
              item.date.split(".")[0] + "." + item.date.split(".")[1]
            ] = item.value;
          });

      break;
    }
  }
  return {};
}

function months(date_now, d) {
  let arr = [];
  let obj = {};
  let months = [
    this.$t("date.months.short.jan"),
    this.$t("date.months.short.feb"),
    this.$t("date.months.short.mar"),
    this.$t("date.months.short.apr"),
    this.$t("date.months.short.may"),
    this.$t("date.months.short.jun"),
    this.$t("date.months.short.jul"),
    this.$t("date.months.short.aug"),
    this.$t("date.months.short.sep"),
    this.$t("date.months.short.oct"),
    this.$t("date.months.short.nov"),
    this.$t("date.months.short.dec")
  ];

  for (d; d <= date_now; d.setMonth(d.getMonth() + 1)) {
    d.setDate(1);
    arr.push(d.getMonth() + "." + d.getFullYear());
  }

  arr.map(m => {
    let date = m.split(".");
    let month = date[0];
    let year = date[1];
    obj[months[month] + " " + year] = 0;
  });

  return obj;
}

function getStartDate(interval) {
  switch (interval) {
    case "year":
      return "01-01-2018 00:00:00";
    case "quarter":
    case "month": {
      let date = this.formatDate();
      date[2] = date[2] - 1;
      return `${date.join("-")} 00:00:00`;
    }
    case "week":
    case "day": {
      let date = new Date();

      let start_date = new Date(
        date.getFullYear(),
        date.getMonth() - 1,
        date.getDate() + 1
      );

      date = [
        start_date.getFullYear().toString(),
        (start_date.getMonth() + 1).toString().length < 2
          ? "0" + (start_date.getMonth() + 1)
          : start_date.getMonth() + 1,
        start_date.getDate().toString().length < 2
          ? "0" + start_date.getDate()
          : start_date.getDate()
      ];

      return `${date.reverse().join("-")} 00:00:00`;
    }
    case "hour": {
      let date = new Date();

      let start_date = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours() - 24
      );

      date = [
        start_date.getFullYear().toString(),
        (start_date.getMonth() + 1).toString().length < 2
          ? "0" + (start_date.getMonth() + 1)
          : start_date.getMonth() + 1,
        start_date.getDate().toString().length < 2
          ? "0" + start_date.getDate()
          : start_date.getDate()
      ];

      return `${date.reverse().join("-")} ${start_date.getHours()}:00:00`;
    }
  }
}

function getEndDate(interval) {
  switch (interval) {
    case "year":
      return `31-12-${new Date().getFullYear()} 23:59:59`;
    case "quarter":
    case "month":
    case "week":
    case "day": {
      let date = this.formatDate();
      return `${date.join("-")} 23:59:59`;
    }
    case "hour": {
      let end_date = new Date();
      let date = this.formatDate();
      return `${date.join("-")} ${end_date.getHours()}:59:59`;
    }
  }
}

function getDatasetKeys(interval, start_date, end_date) {
  let splitted_start = start_date.split(/:| |-/);
  let splitted_end = end_date.split(/:| |-/);
  let date_now = new Date(
    splitted_end[2],
    splitted_end[1] - 1,
    splitted_end[0],
    splitted_end[3],
    splitted_end[4],
    splitted_end[5]
  );
  let d = new Date(
    splitted_start[2],
    splitted_start[1] - 1,
    splitted_start[0],
    splitted_start[3],
    splitted_start[4],
    splitted_start[5]
  );
  switch (interval) {
    case "year": {
      let arr = [];
      let obj = {};

      for (d; d <= date_now; d.setYear(d.getFullYear() + 1)) {
        arr.push(d.getFullYear());
      }
      arr.map(year => {
        obj[year] = 0;
      });

      return obj;
    }
    case "quarter": {
      let arr = [];
      let obj = {};

      for (
        d;
        d.getQuart() <= date_now.getQuart() ||
        d.getFullYear() < date_now.getFullYear();
        d.setMonth(d.getMonth() + 3)
      ) {
        if (
          d.getQuart() === date_now.getQuart() &&
          d.getFullYear() === date_now.getFullYear()
        ) {
          arr.push(d.getFullYear() + "-" + d.getQuart());
          break;
        }
        arr.push(d.getFullYear() + "-" + d.getQuart());
      }
      arr.map(quart => {
        obj[quart] = 0;
      });

      return obj;
    }
    case "month":
      return this.months(date_now, d);
    case "week": {
      let arr = [];
      let obj = {};

      for (
        date_now;
        date_now.getWeek() >= d.getWeek() ||
        date_now.getFullYear() != d.getFullYear();
        date_now.setDate(date_now.getDate() - 7)
      ) {
        let week = new Date(
          date_now.getFullYear(),
          0,
          date_now.getWeek() * 7 - 3
        );
        let last_week = new Date(
          date_now.getFullYear(),
          0,
          date_now.getWeek() * 7 - 9
        );
        arr.push(
          last_week.getDate() +
            "." +
            (last_week.getMonth() + 1) +
            (last_week.getFullYear() === week.getFullYear()
              ? ""
              : `.${last_week.getFullYear()}`) +
            " - " +
            week.getDate() +
            "." +
            (week.getMonth() + 1) +
            "." +
            week.getFullYear()
        );
      }

      arr.reverse().map(week => {
        obj[week] = 0;
      });
      return obj;
    }
    case "day": {
      let arr = [];
      let obj = {};
      for (d; d <= date_now; d.setDate(d.getDate() + 1)) {
        arr.push(
          d.getDate() +
            "." +
            ((d.getMonth() + 1).toString().length < 2
              ? "0" + (d.getMonth() + 1)
              : d.getMonth() + 1) +
            "." +
            d.getFullYear()
        );
      }
      arr.map(day => {
        obj[day] = 0;
      });

      return obj;
    }
    case "hour": {
      let arr = [];
      let obj = {};
      for (d; d <= date_now; d.setHours(d.getHours() + 1)) {
        arr.push(
          d.getDate() +
            "." +
            ((d.getMonth() + 1).toString().length < 2
              ? "0" + (d.getMonth() + 1)
              : d.getMonth() + 1) +
            " " +
            (d.getHours().toString().length < 2
              ? "0" + d.getHours()
              : d.getHours()) +
            ":00"
        );
      }
      arr.map(hour => {
        obj[hour] = 0;
      });

      return obj;
    }
  }
}

function formatDate() {
  let d = new Date(),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [day, month, year];
}

export {
  formatStartDate,
  formatEndDate,
  dataMapper,
  months,
  getStartDate,
  getEndDate,
  getDatasetKeys,
  formatDate
};
