<template>
  <mega-card class="rounded" :loading="!chartData.loaded">
    <div class="card-body">
      <div class="d-flex" style="align-items: center;">
        <h3 class="strong text-success mb-0" style="line-height: 1">
          {{ chartData.count }}
          <span class="text-muted text-dark" style="font-size: 1rem">{{
            $t("by_time")
          }}</span>
        </h3>
        <div style="flex-grow: 1" />
        <h3 class="strong text-success mb-0" style="line-height: 1">
          <span class="text-muted text-dark" style="font-size: 1.2rem">{{
            $t("total")
          }}</span>
          {{ chartData.totalCount }}
        </h3>
      </div>
      <span>{{ $t(`${chartName}`) }}</span>
      <br />
      <label for="select" class="text-muted"
        >{{ $t("business_decomposition_interval") }}:</label
      >
      <select
        @change="
          getChartData(
            chartData.interval,
            chartData.date ? chartData.date[0] : undefined,
            chartData.date ? chartData.date[1] : undefined
          )
        "
        id="select"
        class="text-muted"
        v-model="chartData.interval"
      >
        <option value="hour">{{ $t("date.interval.hour") }}</option>
        <option value="day" selected>{{ $t("date.interval.day") }}</option>
        <option value="week">{{ $t("date.interval.week") }}</option>
        <option value="month">{{ $t("date.interval.month") }}</option>
        <option value="quarter">{{ $t("date.interval.quarter") }}</option>
        <option value="year">{{ $t("date.interval.year") }}</option>
      </select>
      <br />
      <label for="chartDate" class="text-muted">
        {{ $t("graph_date") }}:
      </label>
      <date-picker
        @change="
          getChartData(
            chartData.interval,
            chartData.date ? chartData.date[0] : undefined,
            chartData.date ? chartData.date[1] : undefined
          )
        "
        class="w-100"
        id="chartDate"
        style="width: 100%"
        v-model="chartData.date"
        type="datetimerange"
        range-separator="—"
        :start-placeholder="$t('graph_start_date')"
        :end-placeholder="$t('graph_end_date')"
      >
      </date-picker>
    </div>

    <div class="card-media rounded-bottom">
      <mega-chart
        v-if="doubleGraph"
        style="height: 200px"
        ratio="10x3"
        :legend="true"
        :labels="Object.keys(dataset.chart.firstChartLine)"
        clear="grid"
        :offset="20"
      >
        <mega-chart-set
          :title="$t(`${firstChartLineName}`)"
          :data="Object.values(dataset.chart.firstChartLine)"
          color="success"
          fill
        />
        <mega-chart-set
          :title="$t(`${secondChartLineName}`)"
          :data="Object.values(dataset.chart.secondChartLine)"
          color="warning"
          fill
        />
      </mega-chart>

      <mega-chart
        v-else
        ratio="10x3"
        :legend="true"
        :labels="
          multiGraph
            ? Object.keys(
                dataset.chart[
                  this.endpoint[0][3]
                    ? this.endpoint[0][3]
                    : this.endpoint[0][0]
                ]
              )
            : Object.keys(dataset.chart)
        "
        clear="grid"
        :offset="10"
      >
        <template v-if="multiGraph">
          <mega-chart-set
            v-for="(point, i) in endpoint"
            :key="point[1]"
            :title="$t(`${point[1]}`)"
            :data="Object.values(dataset.chart[point[3] ? point[3] : point[0]])"
            :color="colors[i]"
            fill
          />
        </template>

        <template v-else>
          <mega-chart-set
            :title="$t(`${chartName}`)"
            :data="Object.values(dataset.chart)"
            color="success"
            fill
          />
        </template>
      </mega-chart>
    </div>
  </mega-card>
</template>

<script>
import { DatePicker } from "element-ui";
import locale from "element-ui/lib/locale";
import "element-ui/lib/theme-chalk/button.css";
import ru from "element-ui/lib/locale/lang/ru-RU";
import en from "element-ui/lib/locale/lang/en";
import {
  formatStartDate,
  formatEndDate,
  dataMapper,
  getStartDate,
  getEndDate,
  getDatasetKeys,
  formatDate,
  months
} from "../../scripts/statsMethods";
export default {
  components: {
    DatePicker
  },
  props: {
    endpoint: [String, Array],
    chartName: String,
    firstChartLineName: String,
    secondChartLineName: String,
    doubleGraph: Boolean,
    multiGraph: Boolean,
    idt_arp: [String, Number],
    idt_user: [String, Number],
    idt_user_owner: [String, Number],
    idt_round: [String, Number],
    idt_model: [String, Number]
  },
  data() {
    return {
      dataset: {
        chart: {
          firstChartLine: {},
          secondChartLine: {}
        }
      },
      chartData: {
        list: [],
        loaded: false,
        count: 0,
        totalCount: 0,
        interval: "day",
        date: [undefined, undefined]
      },
      colors: ["success", "warning"]
    };
  },
  mounted() {
    locale.use(this.$i18n.locale === "ru" ? ru : en);

    this.formatStartDate = formatStartDate;
    this.formatEndDate = formatEndDate;
    this.dataMapper = dataMapper;
    this.getStartDate = getStartDate;
    this.getEndDate = getEndDate;
    this.getDatasetKeys = getDatasetKeys;
    this.formatDate = formatDate;
    this.months = months;
    this.date_now = this.formatDate();

    this.formattedEndDate = `${this.date_now[0]}-${this.date_now[1]}-${this.date_now[2]} 23:59:59`;
    this.getTotalChartData("year");

    this.getChartData(this.chartData.interval);
  },
  methods: {
    getTotalChartData(interval, start_date = undefined, end_date = undefined) {
      if (!start_date) {
        start_date = this.getStartDate(interval);
      } else {
        start_date = this.formatStartDate(start_date);
      }

      if (!end_date) {
        end_date = this.getEndDate(interval);
      } else {
        end_date = this.formatEndDate(end_date);
      }

      if (!this.doubleGraph) {
        if (!this.multiGraph) {
          this.$api.v2base
            .get(`${this.endpoint}/${interval}`, {
              params: {
                idt_arp_owner: this.idt_arp,
                idt_user_owner: this.idt_user_owner,
                idt_round: this.idt_round,
                idt_model: this.idt_model,
                start_date: "02-01-2006 15:04:05",
                end_date: this.formattedEndDate
              }
            })
            .then(response => {
              let data = response.data;
              this.chartData.totalCount = data.reduce(
                (acc, item) => acc + item.count,
                0
              );
            });
        } else {
          for (let point of this.endpoint) {
            let parameter = point[2];
            let paramValue = point[3];
            this.$api.v2base
              .get(`${point[0]}/${interval}`, {
                params: {
                  idt_arp_owner: this.idt_arp,
                  idt_user_owner: this.idt_user_owner,
                  idt_round: this.idt_round,
                  idt_model: this.idt_model,
                  start_date: "02-01-2006 15:04:05",
                  end_date: this.formattedEndDate,
                  [parameter]: paramValue
                }
              })
              .then(response => {
                let data = response.data;
                if (point[0] != "/stat/common/modificationweight") {
                  this.chartData.totalCount += data.reduce(
                    (acc, item) => acc + item.count,
                    0
                  );
                }
                if (point[0] === "/stat/common/modificationweight") {
                  this.chartData.count = this.chartData.totalCount;
                }
              });
          }
        }
      } else {
        this.$api.v2base
          .get(`${this.endpoint}/${interval}`, {
            params: {
              idt_arp_owner: this.idt_arp,
              idt_user_owner: this.idt_user_owner,
              idt_round: this.idt_round,
              idt_model: this.idt_model,
              start_date: "02-01-2006 15:04:05",
              end_date: this.formattedEndDate
            }
          })
          .then(response => {
            let data = response.data;
            this.chartData.totalCount = data.reduce(
              (acc, item) => acc + item.count,
              0
            );
          });
      }
    },

    getChartData(interval, start_date = undefined, end_date = undefined) {
      if (!start_date) {
        start_date = this.getStartDate(interval);
      } else {
        start_date = this.formatStartDate(start_date);
      }

      if (!end_date) {
        end_date = this.getEndDate(interval);
      } else {
        end_date = this.formatEndDate(end_date);
      }

      this.chartData.loaded = false;

      if (this.doubleGraph) {
        this.dataset.chart.firstChartLine = this.getDatasetKeys(
          interval,
          start_date,
          end_date
        );
        this.dataset.chart.secondChartLine = this.getDatasetKeys(
          interval,
          start_date,
          end_date
        );
      } else if (this.multiGraph) {
        for (let point of this.endpoint) {
          this.dataset.chart[
            point[3] ? point[3] : point[0]
          ] = this.getDatasetKeys(interval, start_date, end_date);
        }
      } else {
        this.dataset.chart = this.getDatasetKeys(
          interval,
          start_date,
          end_date
        );
      }

      if (!this.doubleGraph) {
        if (!this.multiGraph) {
          this.$api.v2base
            .get(`${this.endpoint}/${interval}`, {
              params: {
                idt_arp_owner: this.idt_arp,
                idt_user_owner: this.idt_user_owner,
                idt_round: this.idt_round,
                idt_model: this.idt_model,
                start_date: start_date,
                end_date: end_date
              }
            })
            .then(response => {
              let data = response.data;
              this.chartData.count = data.reduce(
                (acc, item) => acc + item.count,
                0
              );

              this.dataMapper(data, interval, start_date, end_date, "chart");

              this.chartData.loaded = true;
            })
            .catch(() => {
              this.chartData.loaded = true;
            });
        } else {
          this.chartData.count = 0;
          for (let point of this.endpoint) {
            let parameter = point[2];
            let paramValue = point[3];
            this.$api.v2base
              .get(`${point[0]}/${interval}`, {
                params: {
                  idt_arp_owner: this.idt_arp,
                  idt_user_owner: this.idt_user_owner,
                  idt_round: this.idt_round,
                  idt_model: this.idt_model,
                  start_date: start_date,
                  end_date: end_date,
                  [parameter]: paramValue
                }
              })
              .then(response => {
                let data = response.data;
                if (point[0] === "/stat/common/modificationweight") {
                  data.map(item => {
                    item.count = Math.trunc(item.count / 1000000);
                  });
                }
                if (
                  point[0] != "/stat/common/modificationcount" &&
                  point[0] != "/stat/common/modificationweight"
                ) {
                  this.chartData.count += data.reduce(
                    (acc, item) => acc + item.count,
                    0
                  );
                } else if (point[0] === "/stat/common/modificationcount") {
                  this.chartData.count = data[data.length - 1].count;
                }

                this.dataMapper(
                  data,
                  interval,
                  start_date,
                  end_date,
                  "chart",
                  point[3] ? point[3] : point[0]
                );

                this.chartData.loaded = true;
              })
              .catch(() => {
                this.chartData.loaded = true;
              });
          }
        }
      } else {
        this.$api.v2base
          .get(`${this.endpoint}/${interval}`, {
            params: {
              idt_arp_owner: this.idt_arp,
              idt_user_owner: this.idt_user_owner,
              idt_model: this.idt_model,
              idt_round: this.idt_round,
              start_date: start_date,
              end_date: end_date
            }
          })
          .then(response => {
            let data = response.data;
            this.chartData.count = data.reduce(
              (acc, item) => acc + item.count,
              0
            );
          })
          .then(() => {
            this.$api.v2base
              .get(`${this.endpoint}/${interval}`, {
                params: {
                  idt_arp_owner: this.idt_arp,
                  idt_user_owner: this.idt_user_owner,
                  idt_round: this.idt_round,
                  idt_model: this.idt_model,
                  start_date,
                  end_date,
                  mode: 1
                }
              })
              .then(response => {
                let data = response.data;
                this.dataMapper(
                  data,
                  interval,
                  start_date,
                  end_date,
                  "chart",
                  "firstChartLine"
                );
              });
          })
          .then(() => {
            this.$api.v2base
              .get(`/stat/common/photo/${interval}`, {
                params: {
                  idt_arp_owner: this.idt_arp,
                  idt_user_owner: this.idt_user_owner,
                  idt_round: this.idt_round,
                  idt_model: this.idt_model,
                  start_date,
                  end_date,
                  mode: 2
                }
              })
              .then(response => {
                let data = response.data;
                this.dataMapper(
                  data,
                  interval,
                  start_date,
                  end_date,
                  "chart",
                  "secondChartLine"
                );
                this.chartData.loaded = true;
              });
          });
      }
    }
  }
};
</script>
