<template>
  <mega-card :title="$t('round_pos')">
    <div class="card-media ratio" :style="{ paddingBottom: padding + '%' }">
      <div class="position-control" @click="panToPosition">
        <img src="../../../../assets/cur_position.png" style="width: 30px" />
      </div>
      <gmap-map
        ref="map"
        class="fill-parent"
        :center="{ lat: lat, lng: lng }"
        :zoom="17"
        :options="{
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: false
        }"
        map-type-id="terrain"
        style="position: absolute;"
      >
        <gmap-marker :position="{ lat: lat, lng: lng }" />
      </gmap-map>
    </div>

    <div class="card-body">
      <p class="mb-0 d-inline mr-2">{{ place }}</p>
      <small class="text-muted">{{ address }}</small>
    </div>
  </mega-card>
</template>

<script>
export default {
  name: "ModelNewPage",
  props: {
    lat: {
      type: Number,
      required: true
    },
    lng: {
      type: Number,
      required: true
    },
    place: {
      type: String
    },
    address: {
      type: String
    },
    ratio: {
      type: String
    }
  },
  computed: {
    padding() {
      let r = this.ratio ? this.ratio.toString().split("x") : [4, 3];

      return (r[1] / r[0]) * 100;
    }
  },
  methods: {
    panToPosition() {
      this.$refs.map.panTo(
        new window["google"].maps.LatLng(this.lat, this.lng)
      );
    }
  }
};
</script>

<style lang="sass" scoped>
.position-control
  display: flex
  justify-content: center
  align-items: center
  width: 40px
  height: 40px
  position: absolute
  bottom: 9rem
  right: 0.6rem
  z-index: 10000
  cursor: pointer
  user-select: none
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px
  border-radius: 2px
  background-color: rgb(255, 255, 255)
</style>
