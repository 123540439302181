<template>
  <main class="models-page">
    <section-stats
      v-if="round['likes']"
      :longVideo="round.long_video"
      :dislikes="round['likes']['dislikes_count']"
      :likes="round['likes']['likes_count']"
      :views="round['view_count']"
      :comments="round['comments']['count']"
    />

    <section>
      <div class="container py-5">
        <div class="row">
          <div class="md-down:col-12 lg:col-5 xl:col-3">
            <mega-card class="card-border card-round">
              <div
                v-if="!round.long_video"
                class="p-absolute z-9"
                @click="toggleFavorite(round['in_favourite'])"
                :style="{ top: '8px', right: '8px' }"
              >
                <i
                  :class="
                    `${round['in_favourite'] ? 'i-star-solid' : 'i-star'}`
                  "
                  :style="{
                    fontSize: '32px',
                    color: `${
                      round['in_favourite'] ? 'rgb(255, 205, 54)' : '#fff'
                    }`
                  }"
                >
                </i>
              </div>
              <div
                class="card-media cursor-pointer card-video"
                ref="media"
                @click="video_toggle"
                v-if="video_link"
              >
                <mega-image
                  ratio="9x16"
                  :src="round['url_preview']"
                  v-if="!round['url_preview']"
                />
                <img
                  class="w-100"
                  :src="round['url_preview']"
                  v-if="round['url_preview']"
                  alt
                />

                <div
                  class="media-overlay overlay-90 overlay-dark media-video active z-100"
                >
                  <video
                    ref="video"
                    class="video-player fill-parent"
                    :src="video_link"
                    loop
                  ></video>
                </div>

                <div
                  class="media-overlay overlay-50 overlay-dark z-200"
                  v-if="!playVideo"
                >
                  <div class="text-center">
                    <span class="sticker sticker-lg bg-white">
                      <i class="i-play"></i>
                    </span>
                  </div>
                </div>
              </div>
              <div class="card-media" v-else-if="round.youtube">
                <mega-youtube ratio="16x9" :src="round.youtube.id" />
              </div>

              <div class="card-media" v-else>
                <mega-image
                  ratio="9x16"
                  :src="round['url_preview']"
                  v-if="!round['url_preview']"
                />
                <img
                  class="w-100"
                  :src="round['url_preview']"
                  v-if="round['url_preview']"
                  alt
                />
              </div>

              <router-link
                :to="{ name: profileType, params: { id: author.id } }"
                class="navbar py-3 text-dark"
              >
                <div class="container-fluid">
                  <div class="navbar-label">
                    <mega-image
                      class="rounded-full navbar-avatar mr-3"
                      ratio="1x1"
                      small
                      :src="author.avatar"
                      style="overflow:hidden;"
                    />

                    <div class="content">
                      <small class="strong">
                        <i
                          v-if="profileType === 'Business'"
                          class="i-globe text-muted text-arround align-middle mr-1"
                        ></i>
                        <span
                          >{{ author.name }}
                          <IconBase
                            v-if="author.verified"
                            viewBox="0 0 312 311"
                            iconColor="#45C1C9"
                            ><UserVerified /></IconBase
                        ></span>
                      </small>
                      <small class="text-muted">@{{ author.username }}</small>
                    </div>
                  </div>
                </div>
              </router-link>

              <nav class="nav flex-column">
                <router-link
                  v-if="profileType === 'Business'"
                  :to="{
                    name: 'User',
                    params: { id: round['profile']['idt_user'] }
                  }"
                  class="nav-link"
                >
                  <i class="i-user"></i>
                  <span>{{ round["profile"].name }}</span>
                </router-link>

                <div class="nav-text text-muted">
                  <i class="i-calendar"></i>
                  <span>{{ round["a_time"] | u2d("MMMM DD YYYY - LT") }}</span>
                </div>
              </nav>
            </mega-card>

            <mega-card :title="$t('round_model')" v-if="modification.idt_model">
              <router-link
                :to="{
                  name: type,
                  params: {
                    model_id: modification.idt_model,
                    modification_id: modification.idt_model_modif
                  }
                }"
                class="navbar py-3 text-dark"
              >
                <div class="container-fluid">
                  <div class="navbar-label">
                    <mega-image
                      class="rounded navbar-avatar mr-3 overflow-hidden"
                      small
                      saveAsImg
                      :src="modification['url_icon']"
                    />

                    <div class="content">
                      <small class="strong">{{ modification.name }}</small>
                      <small class="text-muted"
                        >#{{ modification.idt_model }}</small
                      >
                    </div>
                  </div>
                </div>
              </router-link>
            </mega-card>

            <qr-code :uri="qrParams" v-if="!round.is_location_hidden" />

            <mega-card>
              <a
                class="btn text-primary rounded-0 d-block"
                target="_blank"
                :href="web_link"
                >{{ $t("open_web") }}</a
              >
              <div class="p-relative mt-1">
                <span
                  v-if="!readyForPush"
                  class="p-absolute z-3 text-white"
                  :style="{
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-50%,-50%)',
                    width: '100%',
                    textAlign: 'center',
                    fontSize: '16px'
                  }"
                  >{{ timeUntilPushReady }}</span
                >
                <div
                  v-if="!readyForPush"
                  class="p-absolute z-2"
                  :style="{
                    left: 0,
                    top: 0,
                    height: '40px',
                    width: '100%',
                    background: 'rgba(0,0,0,0.75)'
                  }"
                ></div>
                <mega-button
                  class="btn-primary rounded-0 w-100"
                  :disabled="!readyForPush"
                  @click="round_to_top"
                  >{{ $t("round_to_top") }}</mega-button
                >
              </div>
              <mega-button
                v-if="!round.long_video"
                class="btn-primary rounded-0 w-100 mt-1"
                @click="round_advertise"
                >{{ $t("advert_round") }}</mega-button
              >
            </mega-card>

            <mega-card class="mt-4">
              <mega-button
                class="btn btn-default text-danger rounded-0 w-100 text-uppercase"
                @click="delete_round"
                >{{ $t("del") }}</mega-button
              >
              <!-- <mega-button
                v-if="!round.long_video"
                class="btn btn-default text-danger rounded-0 w-100 text-uppercase"
                @click="delete_round"
                >{{ $t("del") }}</mega-button
              >
              <mega-card v-else>
                <mega-button
                  class="btn btn-default text-danger rounded-0 w-100 text-uppercase"
                  @click="showDeleteOptions = !showDeleteOptions"
                >
                  {{ $t("deletion") }}
                </mega-button>
                <div v-if="showDeleteOptions">
                  <mega-switch
                    class="w-100 mb-0 p-2"
                    :valid="false"
                    :label="$t('del_from_video_feed')"
                    @click="deleteFromFeed = !deleteFromFeed"
                    :checked="deleteFromFeed"
                  />
                  <mega-switch
                    class="w-100 mb-0 p-2"
                    :valid="false"
                    :label="$t('del_from_recommended_feed')"
                    @click="deleteFromRecommended = !deleteFromRecommended"
                    :checked="deleteFromRecommended"
                  />

                  <mega-button
                    class="btn btn-default text-danger rounded-0 w-100 text-uppercase"
                    @click="delete_round"
                  >
                    {{ $t("del") }}
                  </mega-button>
                </div>
              </mega-card> -->
            </mega-card>

            <!-- <mega-card v-if="round.long_video" class="mt-4">
              <mega-button
                class="btn btn-default text-danger rounded-0 w-100 text-uppercase"
                @click="block_round"
                >{{ $t("block") }}</mega-button
              >
            </mega-card> -->
          </div>

          <div class="col">
            <div class="row">
              <div class="sm-up:col-12">
                <chart
                  v-if="round.idt_round"
                  :endpoint="
                    author.type === 'arr'
                      ? '/stat/arp/roundviews'
                      : '/stat/user/roundviews'
                  "
                  chartName="views"
                  :doubleGraph="false"
                  :idt_round="round.idt_round"
                />
              </div>
            </div>

            <div v-if="!round.long_video" class="row">
              <div class="sm-down:col-12 md:col-6 lg:col-12 xl:col-6">
                <chart
                  v-if="round.idt_round"
                  :endpoint="
                    author.type === 'arr'
                      ? '/stat/arp/roundlikes'
                      : '/stat/user/roundlikes'
                  "
                  chartName="likes"
                  :doubleGraph="false"
                  :idt_round="round.idt_round"
                />
              </div>
              <div class="sm-down:col-12 md:col-6 lg:col-12 xl:col-6">
                <chart
                  v-if="round.idt_round"
                  endpoint="/stat/share/round"
                  chartName="shares"
                  :doubleGraph="false"
                  :idt_round="round.idt_round"
                />
              </div>
            </div>

            <div v-if="round.long_video" class="sm-up:col-12 px-1">
              <mega-card :title="$t('title')" class="d-flex flex-column">
                <mega-input v-model="round.title" class="px-3" :max="30" />
                <span
                  @click="changeRoundTitle"
                  class="btn btn-primary mr-3 mb-2 font-small align-self-end"
                >
                  {{ $t("change") }}
                </span>
              </mega-card>
            </div>

            <div class="sm-up:col-12 px-1">
              <mega-card :title="$t('desc')" class="d-flex flex-column">
                <mega-textarea
                  v-model="round.message"
                  class="px-3"
                  :max="300"
                />
                <span
                  @click="changeRoundDescription"
                  class="btn btn-primary mr-3 mb-2 font-small align-self-end"
                >
                  {{ $t("change") }}
                </span>
              </mega-card>
            </div>

            <div class="sm-up:col-12 px-1">
              <mega-card :title="$t('category')" class="d-flex flex-column">
                <mega-select
                  class="px-3"
                  v-if="round.long_video"
                  :options="categories"
                />
              </mega-card>
            </div>

            <no-ssr>
              <map-card
                ratio="16x9"
                v-if="!round.is_location_hidden && !round.long_video"
                :place="round['place_address']"
                :address="round['place_name']"
                :lat="round.lat || 0"
                :lng="round.lng || 0"
              />
            </no-ssr>
            <qr-code :uri="qrParams" v-if="round.is_location_hidden" />
            <History
              :modif_id="modification['idt_model_modif']"
              @onGetLastPush="validatePushToTop"
            />
          </div>
        </div>
      </div>
    </section>

    <delete-confirm
      ref="delete-confirm"
      :title="`${$t('del_round')} #${roundId}`"
      @confirm="deleteRound"
      :text="$t('del_confirm')"
      :round="roundId"
    />

    <to-top
      ref="to-top"
      :round="roundId"
      :enabled="readyForPush"
      :likes="Number(likes.count)"
    />
    <advertise v-if="!round.long_video" ref="advertise" :round="roundId" />
  </main>
</template>

<script>
import MapCard from "./components/map";
import SectionStats from "./components/stats";
import DeleteConfirm from "../../components/delete-confirm";
import QrCode from "./../../components/QrCode";
import ToTop from "./components/to-top";
import Advertise from "./components/advertise";
import History from "./components/history";
import IconBase from "../../../components/icons/IconBase.vue";
import UserVerified from "../../../components/icons/set/UserVerified.vue";
import locale from "element-ui/lib/locale";
import "element-ui/lib/theme-chalk/button.css";
import ru from "element-ui/lib/locale/lang/ru-RU";
import en from "element-ui/lib/locale/lang/en";
import Chart from "../../components/Chart";

export default {
  name: "RoundPage",
  props: ["roundId"],
  data() {
    let month = new Date().getMonth();
    let monthsObj = {};
    [
      this.$t("date.months.short.jan"),
      this.$t("date.months.short.feb"),
      this.$t("date.months.short.mar"),
      this.$t("date.months.short.apr"),
      this.$t("date.months.short.may"),
      this.$t("date.months.short.jun"),
      this.$t("date.months.short.jul"),
      this.$t("date.months.short.aug"),
      this.$t("date.months.short.sep"),
      this.$t("date.months.short.oct"),
      this.$t("date.months.short.nov"),
      this.$t("date.months.short.dec")
    ]
      .filter((m, i) => i <= month)
      .map(m => (monthsObj[m] = 0));

    return {
      dataset: {
        likes: {},
        views: {},
        shares: {}
      },
      countViews: 0,
      countLikes: 0,
      views: {
        list: [],
        loaded: false,
        count: 0,
        interval: "day",
        date: [undefined, undefined]
      },
      likes: {
        list: [],
        loaded: false,
        count: 0,
        interval: "day",
        date: [undefined, undefined]
      },
      shares: {
        list: [],
        loaded: false,
        count: 0,
        interval: "day",
        date: [undefined, undefined]
      },
      loaded: false,
      playVideo: false,
      url:
        process.env.APP_ENV === "master"
          ? "https://ar.arround.world/"
          : "https://ardev.arround.world/",
      round: {},
      author: {},
      modification: {},
      categories: [],
      readyForPush: true,
      timerLastPush: -1,
      timeUntilPushReady: "",
      showDeleteOptions: false,
      deleteFromFeed: false,
      deleteFromRecommended: false
    };
  },
  computed: {
    qrParams() {
      return `type=round&id=${this.roundId}`;
    },
    profileType() {
      let type = undefined;

      if (this.author.type === "user") type = "User";
      if (this.author.type === "arp") type = "Business";

      return type;
    },

    type() {
      let res = undefined;

      if (this.round.idc_round_type === 4) res = "Model";
      if (this.round.idc_round_type === 5) res = "Gif";

      return res;
    },

    web_link() {
      return this.url + this.author.username + "/" + this.round["idt_round"];
    },

    video_link() {
      return this.round.media
        ? this.round.media["url"]
        : this.round.videos
        ? this.round.videos[0]["url"]
        : null;
    }
  },
  beforeDestroy() {
    clearInterval(this.timerLastPush);
  },
  mounted() {
    locale.use(this.$i18n.locale === "ru" ? ru : en);

    this.getRound();

    // Получаем список доступных категорий
    // ВРЕМЕННО КАТЕГОРИИ СТРИМОВ
    this.$api.v2base.get("/streams/categories").then(res => {
      res.data.forEach(category => {
        this.categories.push({
          value: category.id,
          name: category.label_ru
        });
      });
    });
  },
  methods: {
    delete_round() {
      this.$refs["delete-confirm"].open();
    },

    round_to_top() {
      this.$refs["to-top"].open();
    },

    round_advertise() {
      this.$refs.advertise.open();
    },

    getRound() {
      this.$api.v2
        .get("/round", { params: { idt_round: this.roundId } })
        .then(response => response.data.round)
        .then(round => {
          this.author = round["from"];
          delete round["from"];

          if (!this.author.username)
            this.author.username = this.author.type + "_" + this.author.id;

          if (round["modification"]) {
            this.modification = round["modification"];
            delete round["modification"];
          }

          // Deprecated
          // if (round["modification_gif"]) {
          //   this.modification = round["modification_gif"];
          //   delete round["modification"];
          // }

          this.round = round;

          if (this.round.long_video)
            this.$navbar.name = `${this.$t("video")} #${this.roundId}`;
          else this.$navbar.name = `${this.$t("round")} #${this.roundId}`;

          this.$render(this.round["url_preview"]).then(() => {
            this.loaded = true;
          });
        })
        .catch(() => {
          this.$router.replace("/404");
        });
    },

    deleteRound(modal) {
      // if (this.round.long_video) {
      // } else {
      this.$api.v2
        .delete("/round", { params: { idt_round: this.roundId } })
        .then(() => {
          this.$refs["delete-confirm"].close();
          this.$router.go(-1);

          modal.close();
        })
        .catch(() => {
          this.$alert.danger("Oops.. Server error");
        });
      // }
    },

    block_round() {
      this.$api.v2
        .delete("/round", { params: { idt_round: this.roundId } })
        .then(() => {
          this.$refs["delete-confirm"].close();
        })
        .catch(() => {
          this.$alert.danger("Oops.. Server error");
        });
    },

    toggleFavorite(inFavorite) {
      if (inFavorite) {
        this.$api.v2base
          .delete("/round/favourites", {
            params: { idt_round: this.round["idt_round"] }
          })
          .then(() => {
            this.getRound();
          })
          .catch(() => {
            this.$alert.danger("Oops.. Server error");
          });
      } else {
        this.$api.v2base
          .post("/round/favourites", { idt_round: this.round["idt_round"] })
          .then(() => {
            this.getRound();
          })
          .catch(() => {
            this.$alert.danger("Oops.. Server error");
          });
      }
    },

    video_toggle() {
      if (this.playVideo) this.$refs["video"].pause();
      else this.$refs["video"].play();

      this.playVideo = !this.playVideo;
    },

    validatePushToTop(timestampLast) {
      const timestampNow = new Date().getTime();
      this.readyForPush =
        timestampNow - timestampLast >= 1000 * 60 * 60 * 24 * 7 ? true : false;
      const dateReady = 1000 * 60 * 60 * 24 * 7 + timestampLast;
      this.timeUntilPushReady = this.getDate(dateReady);
      this.timerLastPush = setInterval(() => {
        this.timeUntilPushReady = this.getDate(dateReady);
      }, 1000);
    },

    getDate(future) {
      const now = new Date().getTime();
      const distance = future - now;

      if (distance < 0) {
        clearInterval(this.timerLastPush);
      }

      let days = Math.floor(distance / (1000 * 60 * 60 * 24));
      let appendix =
        days >= 5
          ? this.$t("round_to_top_day_1")
          : days > 1
          ? this.$t("round_to_top_day_2")
          : days == 0
          ? this.$t("round_to_top_day_1")
          : this.$t("round_to_top_day_3");
      let hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = Math.floor((distance % (1000 * 60)) / 1000);
      return `${days} ${appendix} ${hours > 10 ? hours : "0" + hours}:${
        minutes > 10 ? minutes : "0" + minutes
      }:${seconds > 10 ? seconds : "0" + seconds}`;
    },
    changeRoundTitle() {
      this.$api.v2base
        .put("/round/round", {
          idt_round: this.round.idt_round,
          title: this.round.title // Ждем API
        })
        .then(() => {
          this.$alert.success("Успешно изменено");
        })
        .catch(() => {
          this.$alert.danger("Ошибка");
        });
    },
    changeRoundDescription() {
      this.$api.v2base
        .put("/round/round", {
          idt_round: this.round.idt_round,
          message: this.round.message
        })
        .then(() => {
          this.$alert.success("Успешно изменено");
        })
        .catch(() => {
          this.$alert.danger("Ошибка");
        });
    }
  },
  components: {
    MapCard,
    SectionStats,
    DeleteConfirm,
    ToTop,
    History,
    QrCode,
    UserVerified,
    IconBase,
    Chart,
    Advertise
  }
};
</script>
