var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "bg-white" }, [
    _c("div", { staticClass: "container xs:d-none" }, [
      _c(
        "div",
        {
          staticClass: "row",
          staticStyle: { "justify-content": "space-around" }
        },
        [
          !_vm.longVideo
            ? _c(
                "div",
                { staticClass: "p-4", staticStyle: { "line-height": "1" } },
                [
                  _c("h4", { staticClass: "mb-0 bold xs:d-inline" }, [
                    _vm._v(_vm._s(_vm.$t("comments")))
                  ]),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "h3 font-light text-primary",
                      class: { "text-hide": _vm.comments === undefined },
                      staticStyle: { transition: ".3s" }
                    },
                    [_vm._v(_vm._s(_vm.comments))]
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "p-4", staticStyle: { "line-height": "1" } },
            [
              _c("h4", { staticClass: "mb-0 bold xs:d-inline" }, [
                _vm._v(_vm._s(_vm.$t("views")))
              ]),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "h3 font-light text-primary",
                  class: { "text-hide": _vm.views === undefined },
                  staticStyle: { transition: ".3s" }
                },
                [_vm._v(_vm._s(_vm.views))]
              ),
              _vm._v(" "),
              _c("input", {
                staticClass: "views-input align-self-center",
                staticStyle: {
                  width: "10ch",
                  height: "3ch",
                  "vertical-align": "text-bottom"
                },
                attrs: { type: "number" },
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                  },
                  keydown: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.changeViews.apply(null, arguments)
                  }
                }
              })
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "p-4", staticStyle: { "line-height": "1" } },
            [
              _c("h4", { staticClass: "mb-0 bold xs:d-inline" }, [
                _vm._v(_vm._s(_vm.$t("likes")))
              ]),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "h3 font-light text-primary",
                  class: { "text-hide": _vm.likes === undefined },
                  staticStyle: { transition: ".3s" }
                },
                [_vm._v(_vm._s(_vm.likes))]
              ),
              _vm._v(" "),
              _c("input", {
                staticClass: "likes-input align-self-center",
                staticStyle: {
                  width: "10ch",
                  height: "3ch",
                  "vertical-align": "text-bottom"
                },
                attrs: { type: "number" },
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                  },
                  keydown: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.changeLikes.apply(null, arguments)
                  }
                }
              })
            ]
          ),
          _vm._v(" "),
          !_vm.longVideo
            ? _c(
                "div",
                { staticClass: "p-4", staticStyle: { "line-height": "1" } },
                [
                  _c("h4", { staticClass: "mb-0 bold xs:d-inline" }, [
                    _vm._v(_vm._s(_vm.$t("dislikes")))
                  ]),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "h3 font-light text-primary",
                      class: { "text-hide": _vm.dislikes === undefined },
                      staticStyle: { transition: ".3s" }
                    },
                    [_vm._v(_vm._s(_vm.dislikes))]
                  )
                ]
              )
            : _vm._e()
        ]
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "container py-3 sm-up:d-none" }, [
      _c("nav", { staticClass: "nav flex-column" }, [
        !_vm.longVideo
          ? _c("span", { staticClass: "btn bg-white text-left w-100" }, [
              _c("i", { staticClass: "i-message-square float-left" }),
              _vm._v(" "),
              _c("span", [
                _vm._v(
                  _vm._s(_vm.$t("comments")) + " - " + _vm._s(_vm.comments)
                )
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("span", { staticClass: "btn bg-white text-left w-100" }, [
          _c("i", { staticClass: "i-eye float-left" }),
          _vm._v(" "),
          _c("span", [
            _vm._v(_vm._s(_vm.$t("views")) + " - " + _vm._s(_vm.views))
          ])
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "btn bg-white text-left w-100" }, [
          _c("i", { staticClass: "i-thumbs-up float-left" }),
          _vm._v(" "),
          _c("span", [
            _vm._v(_vm._s(_vm.$t("likes")) + " - " + _vm._s(_vm.likes))
          ])
        ]),
        _vm._v(" "),
        !_vm.longVideo
          ? _c("span", { staticClass: "btn bg-white text-left w-100" }, [
              _c("i", { staticClass: "i-thumbs-down float-left" }),
              _vm._v(" "),
              _c("span", [
                _vm._v(
                  _vm._s(_vm.$t("dislikes")) + " - " + _vm._s(_vm.dislikes)
                )
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.longVideo
          ? _c("span", { staticClass: "btn bg-white text-left w-100" }, [
              _c("i", { staticClass: "i-share float-left" }),
              _vm._v(" "),
              _c("span", [
                _vm._v(_vm._s(_vm.$t("reposts")) + " - " + _vm._s(_vm.reposts))
              ])
            ])
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }